import axios from 'axios';

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet"
let auction_api_endpoint
if(providerEnv === "MainNet") {
  auction_api_endpoint = 'https://dry-bastion-74272.herokuapp.com'
  //auction_api_endpoint = 'http://localhost:3000'
} else {
  auction_api_endpoint = 'https://mysterious-oasis-02272.herokuapp.com'
}
auction_api_endpoint = 'http://localhost:3001'

export default {

  get: async (query = {}) => {
    let res = await axios.get(`${auction_api_endpoint}/api/v1/offers`, {
      params: query,
      headers: {
	'Access-Control-Allow-Origin': true,
      },
    });
    return res.data || [];
  },

}